<script>
import ProjectCard from "./ProjectCard.vue";
import ProjectTable from './ProjectTable.vue'
import SortSelect from "../common/SortSelect.vue";
import PlusIcon from "../../assets/vue-icons/plus-icon.vue";
import gridViewIcon from "../../assets/vue-icons/grid-view-icon.vue";
import tableViewIcon from "../../assets/vue-icons/table-view-icon.vue";
import ActionButton from "../common/ActionButton.vue";
import Spinner from "../common/Spinner.vue";
import SharingDialog from "../common/Sharing/SharingDialog.vue";
import newProjectModal from "./NewProjectModal.vue";
import EditProjectModal from "./EditProjectModal.vue";
import cardEmptyStack from "../../assets/vue-icons/card-empty-stack.vue";
import personalNavItemIcon from "../../assets/vue-icons/personal-nav-item-icon.vue";
import AllProjectNavIcon from "../../assets/vue-icons/all-project-nav-icon.vue";
import sharedWithMeNavIcon from "../../assets/vue-icons/shared-with-me-nav-icon.vue";
import UpdateUserNameView from '../../views/profile/updateUserName.vue';
import DeleteProjectModal from './DeleteProjectModal.vue';
import { computed, onMounted, onBeforeUnmount, inject, ref, reactive, watch } from "vue";
import { REPRESENTATION } from "../common/constants.js";
import { BROADCAST_MESSAGE, ERROR_TOAST, PUBLIC_VIEWER, SUCCESS_TOAST } from "../../core/constants.js";
import { getters, mutations } from "../../store/index.js";


import ProjectService, { PROJECT_CONSTANT } from '../../service/projectService';
import * as automerge from "@automerge/automerge"
import { SET_CACHE,GET_CACHE } from "../../store/cacheLayer.js";

export default {
  name: "Home",
  components: {
    SharingDialog,
    ProjectCard,
    ProjectTable,
    EditProjectModal,
    Spinner,
    AllProjectNavIcon,
    sharedWithMeNavIcon,
    cardEmptyStack,
    PlusIcon,
    newProjectModal,
    gridViewIcon,
    SortSelect,
    tableViewIcon,
    ActionButton,
    personalNavItemIcon,
    UpdateUserNameView,
    DeleteProjectModal
  },
  setup() {
    const $fetch = inject("$fetch");
    const $auth0 = inject("$auth0");
    const deletedProject = ref(null);
    const deletedProjectId = ref(null);
    const deletedProjectName = ref(null)
    const renamedProject = ref('');
    const projectsId = ref("");
    const dataCollaborators = reactive({
      collaborators: [],
      isOwner: false,
      publicStatus: '',
    });
    const currentUserRole = ref('');
    const showSharedDialog = ref(false);
    const showNewProjectModal = ref(false);
    const sharedProject = ref(null);
    const currentUserEmail = ref("");
    const isLoading = ref(false);
    const isProjectDeleting = ref(false);
    const state = reactive({
      projects: [],
      representation: {
        home_tab: null,
        view: null,
        sort: null,
        dir: null,
      }
    });
    const sortedProjects = computed(() => {
      const projects = state.projects || [];
      const { home_tab, sort, dir } = state.representation;
      const sortFn = REPRESENTATION.SORT_FUNC[sort]?.[dir];
      return projects.sort(sortFn).filter(project => {
        switch (home_tab) {
          case REPRESENTATION.HOME_TABS.PRIVATE: return project.userRole === "OWNER"
          case REPRESENTATION.HOME_TABS.SHARED: return project.userRole !== "OWNER"
          default: return true;
        }
      });
    });

    let db2;
    const projectService = new ProjectService();
    onMounted(async () => {
      const store = JSON.parse(localStorage.getItem("representation") || "{}");
      state.representation.home_tab = store["home"]?.home_tab || REPRESENTATION.DEFAULT.HOME_TAB;
      state.representation.view = store["home"]?.view || REPRESENTATION.DEFAULT.VIEW;
      state.representation.sort = store["home"]?.sort || REPRESENTATION.DEFAULT.SORT;
      state.representation.dir = store["home"]?.dir || REPRESENTATION.DEFAULT.DIRECTION;
      isLoading.value = true
      const user = await $auth0.getUser();
      currentUserEmail.value = `${user.profile.email}`.toLowerCase();
      getProjects();
    });
    onBeforeUnmount(() => {
    });
    const getProjects = async (filter = {}) => {
      state.projects = [];
      const { projectType = PROJECT_CONSTANT.PROJECT_TYPES.ALL } = filter;
      let continueLoop = false;
      let projectsCursor = null;
      const cachedProjects = await GET_CACHE.getCachedProjects();
      if(cachedProjects.length>0){
        state.projects = cachedProjects;
        isLoading.value = false;
      }
      let allProjectsBackgroundSync = [];
      do {
        const { hasNextPage, endCursor, projects = [] } = await projectService.getProjects({ projectType, projectsCursor });
        // Temporary loop to get all projects
        //TODO: remove the loop and bind this to user action like paging or infinite scroll
        allProjectsBackgroundSync = [...allProjectsBackgroundSync,...projects];
        projectsCursor = endCursor;
        continueLoop = hasNextPage;
        isLoading.value = false
      } while (continueLoop)
      SET_CACHE.cacheProjects({projects:allProjectsBackgroundSync});
      // update the UI onces sync all projects
      // permission can be revoked or any other prop can be changed when sync with server. so need to render fresh
      state.projects= allProjectsBackgroundSync;

    }

    watch(() => state.representation.view, saveRepresentation);
    watch(() => state.representation.sort, saveRepresentation);
    watch(() => state.representation.dir, saveRepresentation);
    watch(() => state.representation.home_tab, saveRepresentation);

    function saveRepresentation() {
      const store = JSON.parse(localStorage.getItem("representation") || "{}");
      store["home"] = state.representation;
      localStorage.setItem("representation", JSON.stringify(store));
    }
    function changeRepresentation(payload) {
      console.log({ payload });
      for (const entry of Object.entries(payload)) {
        state.representation[entry[0]] = entry[1];
      }
    }
    function fetchProjects() {
      // $fetch.getProjects().then(projects => {
      //   state.projects = projects;
      // });
      isLoading.value = true
      getProjects()
    }
    function createProject() {
      showNewProjectModal.value = true;
    }
    async function duplicateProject(projectId) {
      const id = await projectService.duplicateProject(projectId);
      if (id) {
        const type = SUCCESS_TOAST;
        const selectedProjectName = state.projects.find((project) => project.id === projectId).name;
        const title = 'Successfully duplicated project';
        const message = `<span><span class="message-toast-name">
                      ${selectedProjectName}
                      </span> has been created and added to your projects.</span>`;
        const toast = {
          type, title, message,
        }
        $fetch.dispatch(BROADCAST_MESSAGE.TOAST, toast);
        getProjects()
      } else {
        message = 'Folia encountered an error when attempting to duplicate this project. Please try again.';
        const toast = {
          type: ERROR_TOAST, title: 'Error duplicating project', message
        }
        $fetch.dispatch(BROADCAST_MESSAGE.TOAST, toast);
      }

    }
    async function rejectShare(projectId) {
      const project = await $fetch.getProject(projectId);
      if (project?.userRole === PUBLIC_VIEWER) {
        return await $fetch.deleteProject(projectId);
      }

      await $fetch.shareProject(projectId, currentUserEmail.value, "REJECTED");
    }
    function shareProject(projectId) {
      sharedProject.value = projectId;
      projectsId.value = projectId;
      const roleCurrentUser = state.projects.find(project => project.id === projectId);
      currentUserRole.value = roleCurrentUser.userRole;
      showSharedDialog.value = true;
    }
    function renameProject(project) {
      renamedProject.value = project;
    }
    async function completeRenamingProject(data) {
      const projectId = renamedProject.value.id;
      if (data) {
        const { name, description } = data;
        // const project = state.projects.find((project) => project.id === projectId)
        // project.name = name;
        // project.description = description;
        // await $fetch.updateProjectName(projectId, name, description);
        // await fetchProjects()
        await projectService.editProject({ projectId, name, description });
        getProjects()
      }
      renamedProject.value = null;

    }
    function removeProject(payload) {
      const { id: projectId, name: projectName } = payload;
      deletedProjectId.value = projectId;
      deletedProjectName.value = projectName;
    }
    async function onProjectDelete(confirm = false) {
      // if (!isProjectDeleting.value && confirm) {
      //   isProjectDeleting.value = true;
      //   const { deleted = true, message: msg = 'Folia encountered an error when attempting to delete the project. Please try again.' } = await projectService.deleteProject(deletedProjectId.value).catch(()=>{
      //     isProjectDeleting.value=false;
      //   });
      //   isProjectDeleting.value=false
      //   if (deleted) {
      //     const message = `<span>The project <span class="message-toast-name">${deletedProjectName.value}</span> has been successfully deleted</span>`;
      //     $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
      //       type: SUCCESS_TOAST, title: 'Successfully deleted project ', message
      //     })
      //   } else {
      //     const message = msg
      //     $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
      //       type: ERROR_TOAST, title: 'Error deleting project', message
      //     })
      //   }
      //   getProjects();

      // }
      if (confirm) {
        getProjects();
      }
      deletedProjectId.value = '';
      deletedProjectName.value = '';
    }

    return {
      REPRESENTATION,
      state, sortedProjects, renamedProject, completeRenamingProject,
      showNewProjectModal, sharedProject, projectsId, currentUserRole,
      showSharedDialog, dataCollaborators, isLoading, deletedProjectName, deletedProjectId,

      createProject, shareProject, renameProject, removeProject,
      duplicateProject, rejectShare, changeRepresentation, fetchProjects, onProjectDelete
    };
  },
};
</script>

<template>
  <UpdateUserNameView></UpdateUserNameView>
  <div class="home_container min-h-svh">
    <div class="home-header w-full p-8 flex flex-col">
      <div class="home-header-row mb-4">
        <div class="home-title">Projects</div>
      </div>
      <div class="home-header-row-nav flex flex-col">
        <div class="flex mb-2 md:flex-row-reverse md:-mb-10">
        <div class="button-actions-container ">
          <button class="new-project-btn" @click="createProject">
            <PlusIcon />
            <span class="title-new-project-btn">New Project</span>
          </button>
        </div>
      </div>
        <div class="nav-tabs-container flex w-4/5">
          <div class="nav-tab-item left-item"
            :class="{ 'nav-tab-item-active': state.representation.home_tab === REPRESENTATION.HOME_TABS.ALL }"
            @click="changeRepresentation({ home_tab: REPRESENTATION.HOME_TABS.ALL })">
            <div class="all-projects-nav-item"
              :class="{ 'all-projects-nav-item-active': state.representation.home_tab === REPRESENTATION.HOME_TABS.ALL }">
              <AllProjectNavIcon />
            </div>
            <span class="nav-item-text"
              :class="{ 'active-nav-item-text': state.representation.home_tab === REPRESENTATION.HOME_TABS.ALL }">All
              Projects</span>
          </div>

          <div class="nav-tab-item middle-item"
            :class="{ 'nav-tab-item-active': state.representation.home_tab === REPRESENTATION.HOME_TABS.PRIVATE }"
            @click="changeRepresentation({ home_tab: REPRESENTATION.HOME_TABS.PRIVATE })">
            <div class="personal-nav-icon"
              :class="{ 'personal-nav-icon-active': state.representation.home_tab === REPRESENTATION.HOME_TABS.PRIVATE }">
              <personalNavItemIcon />
            </div>
            <span class="nav-item-text"
              :class="{ 'active-nav-item-text': state.representation.home_tab === REPRESENTATION.HOME_TABS.PRIVATE }">Personal</span>
          </div>

          <div class="nav-tab-item right-item"
            :class="{ 'nav-tab-item-active': state.representation.home_tab === REPRESENTATION.HOME_TABS.SHARED }"
            @click="changeRepresentation({ home_tab: REPRESENTATION.HOME_TABS.SHARED })">
            <div class="shared-nav-icon"
              :class="{ 'shared-nav-icon-active': state.representation.home_tab === REPRESENTATION.HOME_TABS.SHARED }">
              <sharedWithMeNavIcon />
            </div>
            <span class="nav-item-text"
              :class="{ 'active-nav-item-text': state.representation.home_tab === REPRESENTATION.HOME_TABS.SHARED }">Shared
              with me</span>
          </div>
        </div>
        <div class="right-side-nav-container">
         
          <!-- <div class="view-mode-container">
            <div class="view-mode-grid" :class="{ selected: state.representation.view === REPRESENTATION.VIEWS.GRID }"
              @click.stop="changeRepresentation({ view: REPRESENTATION.VIEWS.GRID })">
              <gridViewIcon />
            </div>
            <div class="view-mode-table" :class="{ selected: state.representation.view === REPRESENTATION.VIEWS.TABLE }"
              @click.stop="changeRepresentation({ view: REPRESENTATION.VIEWS.TABLE })">
              <tableViewIcon />
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div v-if="isLoading" class="home-body-empty min-h-svh">
      <div class="loading-overlay">
        <Spinner width="80px" height="80px" />
      </div>
    </div>

    <div v-if="!isLoading && sortedProjects.length === 0" class="home-body-empty">
      <div class="empty-project-screen">
        <div class="empty-card-stack">
          <cardEmptyStack />
        </div>
        <div class="empty-project-text-container">
          <div class="empty-project-title">
            <span v-if="state.representation.home_tab === REPRESENTATION.HOME_TABS.SHARED">No Shared Projects</span>
            <span v-else>No Existing Projects</span>
          </div>
          <div class="empty-project-text">
            <span>Click below to create your first project.</span>
          </div>
        </div>
        <button class="new-project-btn" @click="createProject">
          <PlusIcon />
          <span>New Project</span>
        </button>
      </div>
    </div>
    <div v-if="sortedProjects.length > 0" class="pl-8 pt-2 mt-2">
        <SortSelect :representation="state.representation" @change="changeRepresentation" class="sort-select" />
    </div>

    <div v-if="sortedProjects.length > 0" class="home-body flex w-full p-8 pt-4 max-h-[60vh] min-h-[50vh] md:max-h-[70vh] overflow-y-scroll">
      <div v-if="state.representation.view === REPRESENTATION.VIEWS.GRID && sortedProjects.length > 0"
        class='project-view-grid grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 2xl:gap-10'>
        <ProjectCard v-for="project of sortedProjects" :key="project.id" :data="project" @duplicate="duplicateProject"
          @reject="rejectShare" @share="shareProject" @rename="renameProject(project)" @remove="removeProject" />
      </div>
      <div v-if="state.representation.view === REPRESENTATION.VIEWS.TABLE && sortedProjects.length > 0"
        class='project-view-table'>
        <ProjectTable :sort="state.representation.sort" :data="sortedProjects" @duplicate="duplicateProject"
          @reject="rejectShare" @share="shareProject" @rename="renameProject" @remove="removeProject" />
      </div>
    </div>
    <SharingDialog v-if="projectsId" :projectId="projectsId" :currentUserRole="currentUserRole"
      :isOpen="showSharedDialog" @fetchProjects="fetchProjects" @close="showSharedDialog = false" />
    <newProjectModal v-if="showNewProjectModal" @fetchProject="fetchProjects" @close="showNewProjectModal = false" />
    <EditProjectModal v-if="renamedProject" :project="renamedProject" @close="completeRenamingProject" />
    <DeleteProjectModal v-if="deletedProjectId" :projectId="deletedProjectId" :projectName="deletedProjectName"
      @close="onProjectDelete" />
    <!-- <folia-simple-modal v-if="deletedProjectId" type="dangerous" header="Delete Project?" confirmation-button="Delete"
      dismiss-button="Cancel" @confirm="onProjectDelete(true)" @dismiss="onProjectDelete(false)">
      <p>Are you sure you want to delete <b>{{ deletedProjectName }}</b>? This will also delete all the files in this
        project.</p>
      <p>You cannot undo this action.</p>
    </folia-simple-modal> -->
  </div>
</template>

<style lang="scss" scoped>
.new-project-btn {
  width: 138px;
  display: flex;
  height: 36px;
  padding: 8px 16px;
  background: #334155;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  border: 1px solid #334155;

  &:hover {
    box-shadow: 0 0 4px 4px #e6e6e6;
  }

  .title-new-project-btn {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: white;
  }
}

.home_container {
  height: 100%;
  display: flex;
  width: 100%;
  background: #F8FAFC;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .home-body-empty {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .empty-project-screen {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .empty-project-text-container {
        margin-top: 40px;
        text-align: center;

        .empty-project-title {
          font-weight: 600;
          font-size: 24px;
          line-height: 32px;
          color: #334155;
        }

        .empty-project-text {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
      }

      .new-project-btn {
        width: 138px;
        margin-top: 20px;
        display: flex;
        height: 36px;
        padding: 8px 16px;
        background: #334155;
        align-items: center;
        justify-content: space-between;
        border-radius: 8px;
        color: white;
        cursor: pointer;
        border: 1px solid #334155;
        padding: 10px;

        &:hover {
          box-shadow: 0 0 4px 4px #e6e6e6;
        }

        .title-new-project-btn {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  .home-body {
    //border: solid 3px red;
    //margin: 0 auto;
    // display: grid;
    // grid-template-rows: auto 1fr;
    // width: 100%;
    // height: 100%;

    .project-view-grid {
      background: #F8FAFC !important;
      // display: grid;
      // grid-template-columns: repeat(auto-fill, minmax(410px, 1fr));
      // grid-auto-rows: minmax(309px, 309px);
      // gap: 24px;
      // overflow-y: auto;
      // margin-bottom: 170px;
      // padding: 24px 80px 24px 80px;
    }

    .project-view-table {
      height: auto;
      width: 100%;
      //display: flex;
      overflow: auto;
      justify-content: center;
      padding-left: 80px;
      margin-bottom: 175px;
      padding-right: 80px;
    }

    .loading-overlay {
      display: flex;
      flex-direction: column;
      margin: auto auto;
      justify-content: center;
      align-items: center;
    }
  }

  .home-header {
    // width: 100%;
    // display: flex;
    // flex-direction: column;
    // -webkit-box-align: center;
    // align-items: center;
    // -webkit-box-pack: justify;
    // padding-top: 24px;
    background: #FFFFFF;
    border-bottom: 1px solid #E2E8F0;

    .home-header-row-nav {
      // width: calc(100% - 160px);
      // display: flex;
      // margin-top: 14px;
      // margin-right: 80px;
      // margin-left: 80px;
      // justify-content: space-between;
      // align-items: center;
      // flex-direction: row;

      .nav-tabs-container {
        // display: flex;
        // text-align: center;
        // align-items: center;
        // justify-content: center;
        // position: relative;
        // height: 45px;

        .nav-tab-item {
          height: 44px;
          max-height: 44px;
          min-height: 44px;
          overflow: hidden;
          width: auto;
          display: flex;
          text-overflow: ellipsis;
          flex-direction: row;
          white-space: normal;
          //margin-right: 12px;
          color: #6E6E86 !important;
          font-size: 17px;
          font-weight: 700;
          cursor: pointer;
          //border: solid 1px silver;

          &.left-item {
            padding: 8px 10px 16px 0;
          }

          &.middle-item {
            padding: 8px 10px 16px 10px;
          }

          &.right-item {
            padding: 8px 0 16px 10px;
          }

          .nav-item-text {
            font-weight: 600;
            font-size: 14px;
            white-space: nowrap;
            line-height: 20px;
            margin-left: 6px;
            margin-right: 2px;
            color: #64748B;
            word-break: unset;
            user-select: none;
            @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
              font-size: 11px;
            }
          }

          .active-nav-item-text {
            color: #334155;
          }

          &:hover {
            //  background-color: #f6f4f4 !important;
            //  border-top-right-radius: 8px;
            //  border-top-left-radius: 8px;
            border-bottom: 3px solid #f1f1f1;
          }

          .all-projects-nav-item-active {
            color: #f04e23;
          }

          .personal-nav-icon-active {
            color: #f04e23;
          }

          .shared-nav-icon-active {
            color: #f04e23;
          }
        }

        .nav-tab-item-active {
          color: #f04e23;
          border-bottom: 3px solid #f04e23 !important;
        }
      }

      .right-side-nav-container {
        display: flex;
        flex-direction: row;
        margin-bottom: 8px;

        .sort-select {
          margin-right: 8px;
        }

        .view-mode-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          background: #E2E8F0;
          border-radius: 8px;
          width: 68px;
          height: 36px;
          padding: 4px;
          gap: 4px;

          .view-mode-grid {
            width: 28px;
            height: 28px;
            padding: 4px;
            color: #64748B;
            border-radius: 2px;
            cursor: pointer;
          }

          .view-mode-grid.selected {
            background: #FFFFFF;
            cursor: unset;
            padding: 4px;
            color: #334155;
            width: 28px;
            height: 28px;
            border-radius: 4px;
          }

          //.view-mode-grid:hover {
          //  filter: drop-shadow(0 0 2px #74aff1);
          //}
          .view-mode-table {
            width: 28px;
            height: 28px;
            padding: 4px;
            color: #64748B;
            border-radius: 2px;
            cursor: pointer;
          }

          .view-mode-table.selected {
            background: #FFFFFF;
            cursor: unset;
            padding: 4px;
            width: 28px;
            color: #334155;
            height: 28px;
            border-radius: 4px;
          }
        }
      }
    }

    .home-header-row {
      // width: 100%;
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      // -webkit-box-align: center;
      // -webkit-box-pack: justify;

      .home-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        color: #334155;
        width: 80px;
        user-select: none;
      }

      .list-header-view-mode {
        display: flex;
        -webkit-box-align: center;
        align-items: center;

        //.view-mode-table:hover {
        //  filter: drop-shadow(0 0 2px #74aff1);
        //}
        .button-actions-container {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          justify-content: center;
          flex-direction: row;

        }
      }
    }
  }

  .creating_dialog {
    border: none;
    box-shadow: #00000029 0 0 5px 2px;
    border-radius: 10px;
    background-color: #ffffff;
    margin: auto;
    width: 350px;
    padding: 15px;

    p {
      font-size: 20px;
    }

    input,
    textarea {
      width: 100%;
      margin-top: 10px;
      outline: none;
      resize: none !important;
    }

    .home_dialog_buttons {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        width: 120px;
      }
    }
  }

  .sharing_dialog {
    border: none;
    box-shadow: #00000029 0 0 5px 2px;
    border-radius: 10px;
    background-color: #ffffff;
    margin: auto;
    width: 500px;
    height: 400px;
    padding: 0;

    form {
      height: 100%;
    }
  }

  .rename_dialog {

    input,
    textarea {
      width: 100%;
      margin-bottom: 10px;
    }

    textarea {
      resize: none;
    }
  }

  .create-project-card {
    min-width: 350px;
    min-height: 250px;
    max-width: 350px;
    max-height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 69, 0, 0.6);

    .card-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid 1px #e2e8f0;
      border-radius: 75px;
      background-color: #f0f0f0;
      cursor: pointer;
      width: 150px;
      height: 150px;
    }

    .card-icon:hover {
      box-shadow: 0 0 10px 8px #e6e6e6;
      color: orangered;
    }
  }
}

/* Tablet (landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  .home-header-row {
    width: calc(100% - 48px) !important;
  }

  .home-header-row-nav {
    // width: calc(100% - 48px) !important;
  }

  // .home-body {
  //   .project-view-table {
  //     padding: 0 !important;
  //   }
  // }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .home-header-row {
    width: 100% !important;
  }

  .home-header-row-nav {
    // width: 100% !important;
  }

  // .project-view-grid {
  //   height: 100% !important;
  // }
}

.app-container {
  @media only screen and (max-width: 768px) {
    // & .home-header {
    //   // max-width: 380px;
    //   overflow-x: scroll;
    //   min-height: 120px;
    // }

    & .home-body {
      // & .project-view-grid {
      //   padding: 45px;
      //   display: flex;
      //   flex-direction: column;
      //   padding-bottom: 200px;
      // }
    }

  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    // & .home-body {
    //   & .project-view-grid {
    //     display: flex;
    //     flex-wrap: wrap;
    //     align-items: center;
    //     padding: 60px;
    //   }
    // }
  }

  @media only screen and (min-device-width: 1024px) and (max-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 1) {
    // & .home-body {
    //   & .project-view-grid {
    //     padding: 30px 0px 10px 145px;
    //   }
    // }
  }
}
</style>
