import { v4 as uuid } from "uuid";
import { getters, mutations as mutate } from "../store/index.js";
import {setApplicationState} from '../store/applicationState.js'
import { ALLOWED_CONTENT_TYPES } from "../views/common/constants.js";
import ProjectService from "../service/projectService.js";
class UploadFiles {
  static JUST_ADDED = "just_added";
  static PENDING = "pending";
  static DONE = "done";
  static ERROR = "error";
  watching4Status = false;

  constructor(app) {
    this.$fetch = app.config.globalProperties.$fetch;
  }

  uploadDocuments(projectId, files) {
    const MAX_FILE_SIZE = 200_000_000;
    const promises = Array.from(files).map((file) => {
      if (file.size > MAX_FILE_SIZE) {
        return Promise.resolve({
          id: uuid(),
          mimeType: file.type,
          name: file.name,
          status: UploadFiles.ERROR,
          message: "EXCEEDS_SIZE",
        });
      }
      return this.#promisifiedFileReader(file).then((base64content) => {
        return {
          projectId,
          id: uuid(),
          mimeType: file.type,
          name: file.name,
          createdAt: new Date().toISOString(),
          base64content,
          status: UploadFiles.JUST_ADDED,
          file: file,
        };
      });
    });
    Promise.all(promises)
      .then((results) => {
        this.documents = results;
        mutate.addFiles2Upload(results);
        this.#startUploading(projectId).catch(console.error);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  #promisifiedFileReader(file) {
    return new Promise((resolve, reject) => {
      try {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.readAsDataURL(file);
      } catch (e) {
        reject(e);
      }
    });
  }

  async #startUploading() {
    const projectService = new ProjectService();
    async function uploadWithConcurrencyLimit(docs, concurrencyLimit) {
      const results = [];
      for (let i = 0; i < docs.length; i += concurrencyLimit) {
        const chunk = docs.slice(i, i + concurrencyLimit);
        const promises = chunk.map(async (document) => {
          try {
            const {
              projectId,
              name,
              mimeType,
              base64content,
              createdAt,
              file,
              id: documentId,
            } = document;
            const payload = {
              projectId: projectId,
              filename: name,
              mimeType: mimeType,
              createdAt: createdAt,
              id: documentId,
            };
            const payload2 = {
              file: file,
            };
            const uploadPath = await projectService.uploadDocumentMp(payload2);

            if (uploadPath !== null) {
              payload['uploadPath'] = uploadPath;
            } else {
              payload["base64content"] = base64content;
            }
            const result = await projectService.uploadDocument(payload);
            const { __typename, message } = result;
            if (__typename === "DocumentProcessingSuccess") {
              mutate.reloadDocumentView(true);
              setApplicationState.setDocumentReloadState(true);
              mutate.updateUploadStatus(documentId, UploadFiles.DONE);
            
            } else if (__typename === "DocumentProcessingError") {
              mutate.updateUploadStatus(documentId, UploadFiles.ERROR, message);
            } else {
              mutate.updateUploadStatus(documentId, UploadFiles.PENDING);
            }
            return { documentId, status: "success" };
          } catch (error) {
            const documentId = document.id;
            mutate.updateUploadStatus(
              documentId,
              UploadFiles.ERROR,
              error.message
            );
            return { documentId, status: "error" };
          }
        });
        const chunkResults = await Promise.all(promises);
        results.push(...chunkResults);
      }
      return results;
    }

    const pendingUploadDocs = getters.uploadedFiles.filter(
      (doc) => doc.status === UploadFiles.JUST_ADDED
    );

    const concurrencyLimit = 5;
     await uploadWithConcurrencyLimit(
      pendingUploadDocs,
      concurrencyLimit
    );
    this.#watch4Status();
  }

  #watch4Status() {
    if (this.watching4Status) return;
    const need2watch = getters.uploadedFiles.some(
      (doc) => doc.status === UploadFiles.PENDING
    );
    if (!need2watch) return;
    this.watching4Status = true;
    this.$fetch
      .getUploadStatus()
      .then((results) => {
        for (const result of results) {
          const { documentId, __typename, message } = result;
          if (__typename === "DocumentProcessingSuccess") {
            mutate.updateUploadStatus(documentId, UploadFiles.DONE);
          } else if (__typename === "DocumentProcessingError") {
            mutate.updateUploadStatus(documentId, UploadFiles.ERROR, message);
          } else {
            mutate.updateUploadStatus(documentId, UploadFiles.PENDING);
          }
        }
      })
      .catch((e) => {
        console.error(e.message);
      })
      .finally(() => {
        setTimeout(() => this.#watch4Status(), 2000);
        this.watching4Status = false;
      });
  }

  async uploadCloudFiles(projectId, storageId, files) {
    mutate.addFiles2Upload(
      files.map((file) => {
        return Object.assign({}, file, {
          status: UploadFiles.JUST_ADDED,
        });
      })
    );
    const cloudStorageFileIds = files.map((f) => f.cloudFileId);
    const documentIds = files.map((f) => f.id);
    let need2watch = false;

    const args = [projectId, storageId, cloudStorageFileIds, documentIds];
    try {
      const results = await this.$fetch.uploadCloudFiles(...args);
      for (const { __typename, documentId, message } of results) {
        if (__typename === "DocumentProcessingSuccess") {    
          mutate.reloadDocumentView(true);
          documentIds.forEach(d=>{
            mutate.updateUploadStatus(d, UploadFiles.DONE);
          })
        } else if (__typename === "DocumentProcessingError") {
          mutate.updateUploadStatus(documentId, UploadFiles.ERROR, message);
        } else if (__typename === "DocumentProcessingQueued") {
          need2watch = true;
        }
      }
      this.#watch4Status();
    } catch (e) {
      documentIds.forEach((documentId) =>
        mutate.updateUploadStatus(documentId, UploadFiles.ERROR)
      );
    }
  }

  addCloudFiles4Watching(files) {
    mutate.addFiles2Upload(
      files.map((file) => {
        return Object.assign({}, file, {
          status: UploadFiles.JUST_ADDED,
        });
      })
    );
    // this.#watch4Status();
  }

  updateCloudFilesStatusAsDone(documentId) {
    mutate.updateUploadStatus(documentId, UploadFiles.DONE);
  }

  updateCloudFilesStatusAsError(documentId, message) {
    mutate.updateUploadStatus(documentId, UploadFiles.ERROR, message);
  }
}

export { UploadFiles };
export default {
  install(app) {
    const $uploadFiles = new UploadFiles(app);
    app.config.globalProperties.$uploadFiles = $uploadFiles;
    app.provide("$uploadFiles", $uploadFiles);
  },
};
